import axios from 'axios';
import { getAuth } from 'firebase/auth';
import app from '../firebase';

const BASE_URL =
  window.location.hostname.includes('localhost') ||
  window.location.hostname.includes('127.0.0.1')
    ? process.env.REACT_APP_BASE_PROD_URL
    : process.env.REACT_APP_BASE_PROD_URL;

const auth = getAuth(app);
let idToken = null;

const getIdToken = async () => {
  if (idToken) return idToken;
  idToken = await auth.currentUser.getIdToken();
  return idToken;
};

const config = { baseURL: BASE_URL };
console.log(`BaseURL ${BASE_URL}`);

export const getSignedUrl = async (password) => {
  const idToken = await getIdToken();

  const response = await axios.post('/getSignedUrl', null, {
    ...config,
    headers: {
      idToken,
      password,
    },
  });

  return response.data;
};

export const uploadToS3 = async (file, signedUrl) => {
  await axios.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type,
    },
  });
};
